import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import { isMobile, isIOS } from 'react-device-detect';
import classNames from 'classnames';
import {
  ComponentPropsWithoutRef,
  ComponentRef,
  forwardRef,
  ReactNode,
  useEffect,
  useRef,
  useState
} from 'react';

const TooltipProvider = TooltipPrimitive.Provider;
const TooltipRoot = TooltipPrimitive.Root;
const TooltipTrigger = TooltipPrimitive.Trigger;
const TooltipArrow = TooltipPrimitive.Arrow;

const TooltipContent = forwardRef<
  ComponentRef<typeof TooltipPrimitive.Content>,
  ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => {
  const tooltipContent = () => (
    <TooltipPrimitive.Content
      ref={ref}
      sideOffset={sideOffset}
      className={classNames(
        'border rounded-sm text-sm text-black shadow-md',
        'pointer-events-auto z-[9999] overflow-hidden',
        'animate-in fade-in-0 zoom-in-95',
        'data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95',
        'data-[side=bottom]:slide-in-from-top-2',
        'data-[side=left]:slide-in-from-right-2',
        'data-[side=right]:slide-in-from-left-2',
        'data-[side=top]:slide-in-from-bottom-2',
        className
      )}
      {...props}
    />
  );

  if (isMobile && isIOS) {
    return (
      <TooltipPrimitive.Portal>{tooltipContent()}</TooltipPrimitive.Portal>
    );
  }

  return <>{tooltipContent()}</>;
});
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

const Tooltip = ({
  children,
  content,
  open,
  defaultOpen,
  onOpenChange,
  delayDuration = 300,
  ...props
}: {
  children: ReactNode;
  content: ReactNode;
  open?: boolean;
  defaultOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  delayDuration?: number;
} & ComponentPropsWithoutRef<typeof TooltipContent>) => {
  const [isOpen, setIsOpen] = useState(open || defaultOpen || false);
  const touchTimeout = useRef<NodeJS.Timeout>(null);

  const handleOpenChange = (nextOpen: boolean) => {
    // Check if it's a touch device
    const isTouchDevice = 'ontouchstart' in window;

    if (isTouchDevice) {
      if (!nextOpen) {
        // Clear any existing timeout
        if (touchTimeout.current) {
          clearTimeout(touchTimeout.current);
        }

        // Set a delay before actually closing
        touchTimeout.current = setTimeout(() => {
          setIsOpen(false);
          onOpenChange?.(false);
        }, 100); // Small delay to allow for interaction
      } else {
        setIsOpen(true);
        onOpenChange?.(true);
      }
    } else {
      // Normal behavior for non-touch devices
      setIsOpen(nextOpen);
      onOpenChange?.(nextOpen);
    }
  };

  useEffect(() => {
    return () => {
      if (touchTimeout.current) {
        clearTimeout(touchTimeout.current);
      }
    };
  }, []);

  return (
    <TooltipRoot
      open={isOpen}
      defaultOpen={defaultOpen}
      onOpenChange={handleOpenChange}
      delayDuration={delayDuration}
    >
      <TooltipTrigger asChild>{children}</TooltipTrigger>
      <TooltipContent
        {...props}
        onPointerDownOutside={e => {
          // Prevent closing on touch inside the content
          e.preventDefault();
          if (props.onPointerDownOutside) {
            props.onPointerDownOutside(e);
          }
        }}
      >
        {content}
      </TooltipContent>
    </TooltipRoot>
  );
};

export {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipProvider,
  TooltipRoot,
  TooltipTrigger
};
